/**
*   @name: modal.scss
*   @description: Estilos para el Modal
*/

.modal {
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    position: fixed;
    background-color: rgba($black, 0.7);

    .modal-content {
        width: 50%;
        border-radius: 4px;
        position: relative;
        padding: $paddingLarge;
        background-color: $white;
    }

    .close-modal {
        top: 16px;
        right: 24px;
        position: absolute;
        i { font-size: 1.2rem; }
    }
    
    .export-content {
        align-items: center;
        justify-content: space-between;
    }
}

@media screen and (max-width: 768px) {
    .modal {
        .modal-content {
            width: 90%;
            padding: $paddingSemi;
        }
    }
}