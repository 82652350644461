/**
*   @name: _clients.scss
*   @description: Estilos de la página de clientes
*/

.controlls {
    align-items: center;
    justify-content: space-between;
}

.reactivate { align-items: flex-start; }

@media screen and (max-width: 768px) {
    .controlls {
        .user { 
            margin-bottom: $marginMedium; 
            justify-content: center;
            align-items: center;
            button {
                width: 100%;
            }
            .input-select {
                width: 100%;
                margin: $marginMedium 0;
            }
        }
    }   
}
