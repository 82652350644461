/**
*   @name: button.scss
*   @description: Estilos de los campos de entrada de información
*/

button { cursor: pointer; }

.btn {    
    cursor: pointer;
    color: $white;
    font-family: $Roboto;
    padding: $paddingMedium;
    font-weight: $weightSemi;
    transition: background-color 300ms ease-in-out;
    &:focus {
        transform: scale(0.97);
    }
}

.btn-radius { border-radius: 4px; }

.btn-primary { 
    background-color: $green; 
    &:hover {
        background-color: rgba($green, 0.8);
    }
}

.btn-secondary { 
    background-color: $secondary; 
    &:hover {
        background-color: rgba($secondary, 0.8);
    }
}

.btn-danger {
    background-color: $danger;
    &:hover {
        background-color: rgba($danger, 0.8);
    }
}

.btn-warning {
    cursor: default;
    background-color: $warning;
    &:hover {
        background-color: rgba($warning, 0.8);
    }
}

.btn-transparent { background-color: transparent; }

.btn-small {
    padding: $paddingNormal $paddingMedium;
}

.login {
    .btn-container {
        .btn {
            min-width: 254px;
        }
    }
}

.table {
    .btn {
        padding: $paddingNormal $paddingLarge;
    }
    .btn-link {
        padding: 16px 0;
    }
}

.modal {
    .btn-export {
        padding: 0 $paddingMedium;
        margin-left: $marginMedium;
    }
    .btn-export-loading {
        width: 168px;
    }
}

.form-users {
    .btn-container {
        justify-content: flex-end;
    }
    .btn-danger {
        margin-left: $paddingNormal;
    }
}

.paginator {
    margin-bottom: $marginNormal;
    button {
        margin: $marginMedium 0;
        background-color: $white;
        padding: $paddingNormal 12px;
        transition: all 250ms ease-in-out;
        &:hover {
            color: $white;
            background-color: rgba($green, 0.5);
        }
    }
    .previus {
        border-radius: 4px;        
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid rgba($gray, 0.6);
        border-right: 0;
    }
    .page {
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid rgba($gray, 0.6);
        border-right: 0;
    }
    .next {
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid rgba($gray, 0.6);
    }
    .page-active {
        color: $white;
        background-color: $green;
    }
}

.popover button {
    background-color: transparent;
}


@media screen and (max-width: 768px) {
    .form-users {
        .btn-container {
            justify-content: center;
        }
        .btn-danger {
            margin-left: $paddingNormal;
        }
    }

    .export-content {
        button { margin-top: $marginNormal; }
    }

}

