/**
*   @name: popover.scss
*   @description: Estilos del mensaje emergente
*/

.popover {
    left: 0;
    bottom: 0;    
    z-index: 1;
    position: fixed;
    padding: $paddingMedium;
}

.popover-success {
    background-color: $info;
}

.popover-danger {
    background-color: $danger;
}