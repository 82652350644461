/**
*   @name: forms.scss
*   @description: Estilos para los componentes de formulario
*/

.form-users {
    border-radius: 4px;
    padding: $paddingLarge;
    background-color: $white;
    border: 1px solid rgba($gray, 0.25);
    
    .ml {
        margin-left: $marginMedium;
    }
    .mr {
        margin-right: $marginMedium;
    }

}

@media screen and (max-width: 768px) {
    .form-users {
        padding: $paddingMedium;
        
        .ml { margin-left: 0; }

        .mr { margin-right: 0; }
    
    }
}