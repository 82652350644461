/**
*   @name: inputs.scss
*   @description: Estilos de los campos de entrada de información
*/

input, select, option, textarea, label { font-family: $Roboto; }

.input {
    color: $darkGray;
    border-radius: 4px;
    border: 2px solid $gray;
    font-weight: $weightSemi;
    padding: $paddingNormal 12px;
    &:focus { border: 2px solid $green; }
}

.input-select {
    color: $darkGray;
    border-radius: 4px;
    font-family: $Roboto;
    border: 2px solid $gray;  
    font-weight: $weightSemi;
    padding: 7px 12px;
    background-color: transparent;
}

/* Checkbox styles */
.checkbox-container {
    display: block;
    cursor: pointer;
    user-select: none;
    position: relative;
    font-size: $fontText;
    padding-left: $marginLarge;
}

.checkbox-container input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
}

.checkbox-container .checkmark {    
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    position: absolute;
    background-color: rgba(#ccc, 0.7);
    transition: all 300ms ease-in-out;
}

.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
    background-color: $secondary;
}

.checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    top: 5px;
    left: 8px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/* End Checkbox styles */

.login {
    .input-group {
        &:focus-within > .icon {
            border: 2px solid $green;
            border-right: 0;
        }
        .icon { 
            width: auto;
            height: 36px; 
            border-radius: 4px;
            padding-right: 1px;
            padding-left: 8px;
            border: 2px solid $gray;
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            transition: all 250ms ease-in-out;
            i { 
                color: #45B3AC;
                font-size: 1.1rem; 
            }
        }
        .input {
            min-width: 224px;
            text-align: center;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-left: 0;
            transition: all 250ms ease-in-out;
        }
    }
}

.table-clients .search {
    margin-right: $marginNormal;
}

.form-users .input-container, .excel .input-container {
    position: relative;
    i {
        top: 11px;
        left: 8px;
        z-index: 1;
        color: #45B3AC;
        position: absolute;
        font-size: 0.9em;
    }
    .input { 
        height: 36px;
        padding-left: $paddingSemi;
    }
    .input-select {
        appearance: none;
        padding-left: 26px;
    }
    .react-datepicker-wrapper, .react-datepicker__input-container {
        width: 100%;
    }
}

.modal .input-container {
    width: 100%;    
    position: relative;
    i {
        top: 10px;
        left: 8px;
        z-index: 1;
        color: #45B3AC;
        position: absolute;
        font-size: $fontText;
    }
    .input {
        padding-left: $paddingSemi;
    }
    .react-datepicker-wrapper, .react-datepicker__input-container {
        width: 100%;
    }
}

.react-datepicker-popper {
    z-index: 10;
}

@media screen and (max-width: 768px) {

    .table-clients .search {
        margin-right: 0;
    }

    .form-users .input-container {
        width: 100%;
        position: relative;
        flex-direction: column;
        i {
            top: 12px;
            left: 8px;
            z-index: 1;
            color: #45B3AC;
            position: absolute;
            font-size: $fontText;
        }
        .input { 
            width: 100%;
            font-size: 0.8rem;
            margin-bottom: 4px;
            padding-left: $paddingSemi;
            border: 1.5px solid $gray;
            &:focus { border: 1.5px solid $green; }
        }
        .input-select {
            padding-left: 27px;
        }
        .react-datepicker-wrapper, .react-datepicker__input-container {
            width: 100%;
        }
    }

    .export-content {
        .input-container {
            margin: 4px 0;
        }
    }

}