/**
*   @name: header.scss
*   @description: Estilos del header
*/

header {
    .header-top {
        background-color: $green;
        .logo img {
            height: 90px;
        }
        .controls {
            justify-content: flex-end;
        }
        .item {            
            p { 
                padding-top: 2px;
                font-size: 0.94rem; 
                padding-right: 10px;
                border-right: 1px solid $white;
            }
        }
        button { 
            font-size: 0.95rem;
            background-color: transparent; 
            padding-left: 10px;
        }
    }
    .header-middle {
        padding: $paddingMedium 0;
        background-color: $white;
        border-bottom: 1px solid rgba($gray, 0.25);
        .container {
            justify-content: space-between;          
        }
        .item {
            color: $green;
            padding: 0 12px;
            &:last-child { padding-right: 0; }
            &:first-child { padding-left: 0; }
            a { transition: color 300ms ease-in-out; }
            &:hover > a{
                color: rgba($green, 0.8);
            }
        }
        .item-active {
            color: $green;
        }
    }
}

@media screen and (max-width: 768px) {
   
    header {
        .header-top {
            .logo {
                width: 100%;
                align-items: center;
                justify-content: center;
            }
            .controls {
                justify-content: center;
                margin-bottom: 10px;
            }
        }
        .header-middle {
            padding: 10px 0;
            background-color: $white;
            border-bottom: 1px solid rgba($gray, 0.25);
            .breadcrumbs { display: none; }
            .navigation { 
                width: 100%;
                flex-wrap: wrap; 
                justify-content: center;
            }
            .item {
                width: 50%;
                padding: 6px 0;
                text-align: center;
            }
        }
    }
}