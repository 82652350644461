/**
*   @name: tables.scss
*   @description: Estilos de tablas
*/

.table {
    .loading {
        min-height: 450px;
    }
    .table-content {
        min-height: 500px;
        max-height: 580px;
        border-radius: 4px;
        padding: $paddingLarge;
        background-color: $white;
        border: 1px solid rgba($gray, 0.25);
        .title {
            justify-content: space-between;
        }
    }
    .id {
        width: 60px;
    }
    table thead {
        td {
            color: $darkGray;
            font-size: 0.95rem;
            font-weight: $weightMedium;
            border-top: 1px solid rgba($gray, 0.5);
            padding: 12px 0;            
            font-weight: $weightSemi;
        }
    }
    table tbody {
        tr {
            transition: background-color 250ms ease-in-out;
            &:hover {
                background-color: rgba($green, 0.075);
            }
        }
        td {
            color: $black;
            padding: $paddingNormal 0;
            border-top: 1px solid rgba($gray, 0.3);
        }
    }
    .status, .status-danger, .status-warning, .status-info {
        border-radius: 4px;
        background-color: $info;
        padding: 4px $paddingNormal;
    }

    .status-danger { background-color: $danger; }

    .status-warning { background-color: $warning; }

    .status-info { background-color: $success; }    
}

.table-clients {
    .title {
        h4 { width: 40%; }
        .excel {
            width: 60%;
            justify-content: flex-end;
        }
    }
}

@media screen and (max-width: 768px) {

    .table {
        .table-content {
            padding: $paddingMedium $paddingSemi;
            .title {
                width: 100%;
                align-items: center;
                flex-direction: column;
                h4 {
                    width: 100%;
                    text-align: center;
                    margin-bottom: $marginNormal;
                }
                button { margin: 6px 0; }
                .excel {
                    align-items: center;
                    width: 100%;
                }
            }      
        }
        table { table-layout: fixed; }
        table thead {
            td {
                padding: 12px 0;
                color: $darkGray;
                font-size: 0.9rem;
            }
        }
        table tbody {
            td {
                width: 250px;
                color: $black;
                padding: $paddingNormal 0;
                border-top: 1px solid rgba($gray, 0.3);
            }
        }
        .name, .email { width: 250px; }
        .phone { width: 160px; }
        .state { width: 120px; }
        .more { width: 150px; }
        .status, .status-danger, .status-warning {
            border-radius: 4px;
            background-color: $info;
            padding: 4px $paddingNormal;
        }
        .status-danger { background-color: $danger; }
        .status-warning { background-color: $warning; }
    }    

}